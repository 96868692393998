<template>
  <div class="column responsive-margin">
    <h1 class="title is-size-4-mobile is-spaced">{{ title }}</h1>
    <p class="subtitle is-6 infoline is-hidden-mobile">
      <span class="right-padding">{{ date }}</span>
      <span class="right-padding boxed">{{ certification }}</span>
      <span
        v-if="info.status != 'Released'"
        class="right-padding"
        style="font-variant: small-caps; white-space: normal;"
      >{{ info.status.toLowerCase() }}</span>
      <span
        v-else
        class="right-padding"
        style="font-variant: small-caps; white-space: normal"
      >{{ duration }}</span>
    </p>
    <p class="subtitle is-6 infoline is-hidden-desktop is-hidden-tablet">
      <span class="right-padding">{{ date }}</span>
      <span class="right-padding boxed">{{ certification }}</span>
    </p>
    <p class="subtitle is-6 infoline is-hidden-desktop is-hidden-tablet">
      <span
        v-if="info.status != 'Released'"
        class="right-padding"
        style="font-variant: small-caps; white-space: normal;"
      >{{ info.status.toLowerCase() }}</span>
      <span
        v-else
        class="right-padding"
        style="font-variant: small-caps; white-space: normal"
      >{{ duration }}</span>
    </p>
    <p class="subtitle is-6" style="margin-bottom: 1rem">
      <span v-if="info.status == 'Released'" class="right-padding">{{ info.vote_average }}</span>
      <span class="right-padding" style="white-space: normal;">{{ genres }}</span>
    </p>

    <button
      v-if="isSignedIn"
      title="Favorite/Unfavorite this item. This will update your viewing preferences to help recommend movies and TV shows that you might like"
      @click="$emit('handle-favorites')"
      class="button is-rounded is-small is-link is-hidden-mobile"
      :class="{favorited: favorite }"
    >
      <font-awesome-icon v-if="!favorite" icon="heart"></font-awesome-icon>
      <font-awesome-icon v-else icon="check"></font-awesome-icon>

      <p style="margin-left: 10px">Favorite</p>
    </button>
    <button
      v-if="isSignedIn"
      @click="$emit('handle-watchlist')"
      title="Add/Remove from your watchlist"
      class="button is-rounded is-link is-small is-hidden-mobile"
      style="margin-left: 1rem"
      :class="{ watchlisted: watchlist }"
    >
      <font-awesome-icon v-if="!watchlist" icon="plus"></font-awesome-icon>
      <font-awesome-icon v-else icon="check"></font-awesome-icon>

      <p style="margin-left: 10px">Watchlist</p>
    </button>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Posterinfo",
  props: ["info", "favorite", "watchlist", "isSignedIn"],
  methods: {
    checkIfLegit(val) {
      var items = [undefined, null, "", " "];
      return items.every((item) => item !== val);
    },
    formatTime(time) {
      const minutes = time.minutes();
      const hours = time.hours();
      const hourFormatStr = hours === 1 ? "hr" : "hrs";
      const minuteFormatStr = minutes === 1 ? "min" : "mins";
      if (!time.minutes()) {
        return time.format(`h [${hourFormatStr}]`);
      }
      return time.format(`h [${hourFormatStr}] m [${minuteFormatStr}]`);
    },
  },
  computed: {
    date() {
      var date = this.info.release_date;
      return this.checkIfLegit(date) ? new Date(date).getFullYear() : "???";
    },
    title() {
      return this.info.title != undefined
        ? this.info.title
        : this.info.original_name;
    },
    genres() {
      if (Object.keys(this.info).length != 0) {
        let genres = [];
        this.info.genres.forEach((genre) => genres.push(genre.name));
        return genres.toString().replace(/,/g, ", ");
      }
    },
    duration() {
      return this.info.runtime < 60
        ? this.info.runtime == 1
          ? this.info.runtime + " min"
          : this.info.runtime + " mins"
        : this.formatTime(
            moment.utc(
              moment.duration(this.info.runtime, "minutes").asMilliseconds()
            )
          );
    },
    certification() {
      if (Object.keys(this.info).length != 0) {
        let cert = "NR";
        this.info.release_dates.results.forEach((country) => {
          if (country.iso_3166_1 == "US") {
            country.release_dates.forEach((date) => {
              if (date.certification != "") {
                cert = date.certification;
                return;
              }
            });
            return;
          }
        });
        return cert;
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
.right-padding {
  margin-right: 10px;
  font-weight: 500;
  white-space: normal;
}

.boxed {
  text-align: center;
  padding-left: 1px;
  padding-right: 1px;
  border: 1.5px solid #4d4d4d;
  border-radius: 2px;
}

.infoline {
  margin-bottom: 1rem;
}

.favorited {
  background-color: pink !important;
}

.watchlisted {
  background-color: dodgerblue !important;
}

.responsive-margin {
  margin-left: 1.5rem !important;
}

@media only screen and (max-width: 460px) {
  .responsive-margin {
    margin-left: 0.5rem !important;
  }
}
</style>