<template>
  <div>
    <div class="tile is-ancestor box">
      <detailparent>
        <p v-for="(item, index) in filteredResults(1)" :key="index">
          <span
            style="font-variant: small-caps; font-size: 1.3rem"
          >{{ Object.keys(item)[0].replace(/_/g, " ") + " - " }}</span>
          <span
            style="font-size: 90%; word-wrap: break-word"
          >{{ item[Object.keys(item)[0]] == "" ? "Unknown" : item[Object.keys(item)[0]]}}</span>
        </p>
      </detailparent>

      <detailparent>
        <p v-for="(item, index) in filteredResults(2)" :key="index">
          <span
            style="font-variant: small-caps; font-size: 1.3rem"
          >{{ Object.keys(item)[0].replace(/_/g, " ") + " - " }}</span>
          <span
            style="font-size: 90%; word-wrap: break-word"
          >{{ item[Object.keys(item)[0]] == "" ? "Unknown" : item[Object.keys(item)[0]]}}</span>
        </p>
      </detailparent>
    </div>

    <div class="tile is-ancestor box" style="margin-top: 2rem;" v-if="info.spoken_languages.length > 0">
      <div class="tile is-vertical is-12">
        <p class="title is-6">Spoken Language(s)</p>
        <p style="margin-left: 1rem;">{{ spokenlangs() }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Detailparent from "@/components/movies/Detailparent.vue";
import langfinder from "iso-639-1";

export default {
  props: ["info"],
  components: {
    Detailparent
  },
  created() {
    this.filteredResults();
    console.log(this.info)
  },
  methods: {
    spokenlangs() {
      var langs = "";
      this.info.spoken_languages.forEach(lang => (langs += lang.name + ", "));
      langs = langs.slice(0, -2);
      return langs;
    },

    filteredResults(section) {
      let output = {};
      let objects = {};
      let items_to_remove = [
        "backdrop_path",
        "belongs_to_collection",
        "credits",
        "genres",
        "overview",
        "poster_path",
        "runtime",
        "similar",
        "title",
        "video",
        "videos",
        "vote_average",
        "vote_count"
      ];

      for (var key in this.info) {
        if (!items_to_remove.includes(key)) {
          if (typeof this.info[key] == "object") {
            objects[key] = this.info[key];
          } else {
            output[key] = this.info[key];
          }
        }
      }

      output.adult == false ? (output.adult = "No") : (output.adult = "Yes");
      output.budget = this.currencyformatter(output.budget);
      output.original_language = langfinder.getName(output.original_language);
      output.revenue = this.currencyformatter(output.revenue);
      if (section == 1) {
        return Object.keys(output)
          .slice(0, 6)
          .map(key => ({ [key]: output[key] }));
      } else if (section == 2) {
        return Object.keys(output)
          .slice(6, 12)
          .map(key => ({ [key]: output[key] }));
      } else {
        return objects;
      }
    },
    currencyformatter(number) {
      var f = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });

      return f.format(number);
    }
  }
};
</script>

<style scoped>
</style>